import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import ReactPlayer from 'react-player/youtube';
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Pairwyse Protocol - P2P Smart Contracting
			</title>
			<meta name={"description"} content={"Pairwyse protocol for on-chain P2P and multi-party agreements, DeFi instruments, risk management, scope enforcement, rights management, counterparty recourse and fully decentralized DAO governance on the blockchain"} />
			<meta property={"og:title"} content={"Pairwyse Protocol"} />
			<meta property={"og:description"} content={"Pairwyse protocol for on-chain P2P and multi-party agreements, DeFi instruments, risk management, scope enforcement, rights management, counterparty recourse and fully decentralized DAO governance on the blockchain"} />
			<meta property={"og:image"} content={"https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/static/media/favicon.ico"} />
			<link rel={"shortcut icon"} href={"https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/static/media/favicon.ico"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/static/media/favicon.ico"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/static/media/favicon.ico"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/static/media/favicon.ico"} />
			<link rel={"apple-touch-startup-image"} href={"https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/static/media/favicon.ico"} />
			<meta name={"msapplication-TileImage"} content={"https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/static/media/favicon.ico"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>

		<Section background="linear-gradient(264.47deg,#000000 0%,#000708 29.39%)" padding="36px 0 36px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="20%"
				sm-width="60%"
				display="flex"
				align-items="center"
				empty-min-width="100px"
				empty-min-height="100px"
				empty-border-color="LightGray"
			>
				<Image src="https://pairwyse.org/static/media/logo.svg" max-width="171px" />
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="100%"
				sm-width="50%"
				align-items="center"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override
						slot="Content"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
					/>
					<Override slot="Children" justify-content="center" display="flex" align-items="center" />
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Box
						display="flex"
						align-items="center"
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
					>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							font="--base * 0.75"
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color="--grey"
							margin="0px 15px 0px 15px"
							opacity="0.95"
							href="https://zerocap.com/research-lab/limits-of-smart-contracts-dao-pairwyse-protocol/"
							target="_blank"
						>
							Whitepaper
						</Link>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							font="--base * 0.75"
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color="--grey"
							margin="0px 15px 0px 15px"
							opacity="0.95"
							href="https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/Clinaro%2C+Ltd+-+Pairwyse+Whitepaper+-+Original.pdf"
							target="_blank"
						>
							Technical
						</Link>
						<Link
							color="--grey"
							margin="0px 15px 0px 15px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							font="--base * 0.75"
							text-decoration-line="initial"
							opacity="0.95"
							hover-opacity="1"
							md-color="--indigo"
							href="https://tetsuya-kuroko-pairwyse-dsl.readthedocs-hosted.com/en/latest/"
							target="_blank"
						>
							Docs
						</Link>
						<Link
							color="--grey"
							margin="0px 15px 0px 15px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							font="--base * 0.75"
							text-decoration-line="initial"
							opacity="0.95"
							hover-opacity="1"
							md-color="--indigo"
							href="https://github.com/akiva-capital-holdings/pairwyse-dsl"
							target="_blank"
						>
							Github
						</Link>
						<Link
							color="--grey"
							margin="0px 15px 0px 15px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							font="--base * 0.75"
							text-decoration-line="initial"
							opacity="0.95"
							hover-opacity="1"
							md-color="--indigo"
							href="https://github.com/akiva-capital-holdings/pairwyse-dsl/blob/workshops/demo/Pairwyse_workshops.md"
							target="_blank"
						>
							Workshops
						</Link>
						<Link
							color="--grey"
							margin="0px 15px 0px 15px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							font="--base * 0.75"
							text-decoration-line="initial"
							opacity="0.95"
							hover-opacity="1"
							md-color="--indigo"
							href="https://t.me/pairwyse_community"
							target="_blank"
						>
							Telegram
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base * 0.75"
							color="--grey"
							opacity="0.95"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							text-decoration-line="initial"
							margin="0px 15px 0px 15px"
							href="https://twitter.com/pairwyse"
							target="_blank"
						>
							Twitter_X
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base * 0.75"
							color="--grey"
							opacity="0.95"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							text-decoration-line="initial"
							margin="0px 15px 0px 15px"
							href="https://github.com/akiva-capital-holdings/pairwyse-dsl/blob/master/demo/nivaura/NIVAURA_DEMO.md"
							target="_blank"
						>
							Testnet
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
				<Section padding="36px 0 80px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0" background="#000000">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="50%"
				padding="0px 40px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Pairwyse Protocol joins Chainlink BUILD
				</Text>
				<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>
					We’re excited to announce Pairwyse has joined the Chainlink BUILD program. As a part of Chainlink BUILD, we aim to accelerate ecosystem growth and adoption of Pairwyse by gaining enhanced access to Chainlink’s industry-leading oracle services and support. By integrating Chainlink CCIP, Data Streams, and Automation into Pairwyse’s on-chain smart contract composition system, Pairwyse users can create user-defined smart contracts underpinned by Chainlink’s secure, reliable, and battle-tested infrastructure. 
				</Text>
				<Box
					lg-margin="32px 0px 0px 0px"
					md-width="100%"
					md-margin="36px 0px 0px 0px"
					display="flex"
					margin="72px 0px 0px 0px"
				>
					<Link
						sm-margin="0px 22px 0px 0px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="/login"
						text-decoration-line="initial"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						sm-padding="15px 13px 15px 13px"
						md-text-align="center"
						lg-margin="0px 32px 0px 0px"
						padding="15px 30px 15px 30px"
						border-radius="10px"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-padding="15px 16px 15px 16px"
						hover-color="--primary"
						color="--white"
						margin="0px 44px 0px 0px"
						lg-padding="15px 23px 15px 23px"
						md-width="50%"
						background="--color-primary"
						font="--lead"
						hover-background="--color-white"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						href="https://medium.com/@pairwyse/pairwyse-protocol-joins-chainlink-build-to-accelerate-adoption-of-onchain-and-user-defined-smart-ec04f934d34f"
						target="_blank"
					>
						Full Announcement
					</Link>
				</Box>
			</Box>
			<Box
				empty-border-style="solid"
				width="50%"
				display="flex"
				justify-content="flex-end"
				empty-border-width="1px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				md-width="100%"
				md-order="-1"
				empty-min-width="64px"
			>
				<Image
					width="100%"
					md-margin="0px 0px 30px 0px"
					src="https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/Pairwyse_Chainlink_Banner_2.png"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 24px 0" sm-padding="40px 0 24px 0" md-padding="40px 0 24px 0" background="#f2f2f2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					src="https://miro.medium.com/v2/resize:fit:1224/format:webp/1*RdBMQUsxOvep2W8IrLUIkA.jpeg"
					left="-140px"
					max-width="100%"
					object-fit="cover"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="50%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>
				<Text
					width="85%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline4"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Non-custodial Active Management -- Pairwyse transforms a Paradox into a Paradigm Shift
				</Text>

				<Text
					font="--base"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					Pairwyse offers a groundbreaking solution for integrating active management into DeFi. Its smart contracts enable managerless vaults, ensuring non-custodial fund management with stringent security. Investors retain control via personalized wallets, while active traders execute strategies without custody. Pairwyse aligns active trading with DeFi principles, advancing towards disintermediated automated markets.
				</Text>
				<Link
					href="/login"
					sm-padding="15px 20px 15px 20px"
					hover-background="rgba(5, 165, 255, 0)"
					border-width="1px"
					border-style="solid"
					font="--lead"
					sm-margin="0px 22px 0px 0px"
					border-color="rgba(255, 255, 255, 0.3)"
					md-width="100%"
					md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					text-decoration-line="initial"
					color="--white"
					margin="0px 44px 0px 0px"
					hover-color="--primary"
					hover-border-color="--color-primary"
					md-text-align="center"
					background="--color-primary"
					padding="15px 30px 15px 30px"
					border-radius="10px"
					sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
					href="https://medium.com/@pairwyse/non-custodial-active-management-f8c626ff928f"
					target="_blank"
				>
					Read the Litepaper
				</Link>
			</Box>
		</Section>
		<Section padding="36px 0 80px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0" background="#000000">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="50%"
				padding="0px 70px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="24px 0px 16px 0px"
					font="--headline4"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Pairwyse is an on-chain P2P-decentralized contracting protocol
				</Text>
				<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>
					Pairwyse enables purely on-chain P2P-decentralized manufacturing, governance, rights management and scope enforcement of smart contracts for DAOs, Economic Entities and General Networks.
				</Text>
				<Box
					lg-margin="32px 0px 0px 0px"
					md-width="100%"
					md-margin="36px 0px 0px 0px"
					display="flex"
					margin="72px 0px 0px 0px"
				>
					<Link
						sm-margin="0px 22px 0px 0px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						text-decoration-line="initial"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						sm-padding="15px 13px 15px 13px"
						md-text-align="center"
						lg-margin="0px 32px 0px 0px"
						padding="15px 30px 15px 30px"
						border-radius="10px"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-padding="15px 16px 15px 16px"
						hover-color="--primary"
						color="--white"
						margin="0px 44px 0px 0px"
						lg-padding="15px 23px 15px 23px"
						md-width="50%"
						background="--color-primary"
						font="--lead"
						hover-background="--color-white"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						href="https://zerocap.com/research-lab/limits-of-smart-contracts-dao-pairwyse-protocol/"
						target="_blank"
					>
						Economic Paper
					</Link>
					<Link
						padding="15px 30px 15px 30px"
						font="--lead"
						border-radius="10px"
						border-width="2px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						border-style="solid"
						md-width="50%"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-text-align="center"
						sm-padding="15px 13px 15px 13px"
						text-decoration-line="initial"
						color="--white"
						background="rgba(255, 5, 5, 0)"
						margin="0px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0.3)"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						hover-background="--color-white"
						hover-color="--indigo"
						href="/login"
						lg-padding="15px 23px 15px 23px"
						md-padding="15px 16px 15px 16px"
						href="https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/Clinaro%2C+Ltd+-+Pairwyse+Whitepaper+-+Original.pdf"
						target="_blank"
					>
						Technical Report
					</Link>
				</Box>
			</Box>
			<Box
				empty-border-style="solid"
				width="50%"
				display="flex"
				justify-content="flex-end"
				empty-border-width="1px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				md-width="100%"
				md-order="-1"
				empty-min-width="64px"
			>
				<ReactPlayer url='https://www.youtube.com/watch?v=jnUZhWpwOZw' />
				
			</Box>
		</Section>
		<Section padding="40px 0 24px 0" sm-padding="40px 0 24px 0" md-padding="40px 0 24px 0" background="#f2f2f2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					src="https://lh4.googleusercontent.com/9St0_jChsFy4EHl6ebTiVCZOf1hLKwywYP_Jn0-QgsODIH1VE3kRkyfLO-t6l_K-pqrF50jvzHwSE5o35m6fVnWYnZQgSqcrbcTDBplwMRSqdwQ-m2JHTZIjn0y5ZBivOyUGi58RElUx8szjqShEASw"
					left="-140px"
					max-width="100%"
					object-fit="cover"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="50%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>
				<Text
					width="85%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline4"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Pairwyse enables completely on-chain DAOs, partnerships and trustless P2P governance of smart contract systems
				</Text>
				<Text
					font="--base"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					Pairwyse eliminates centralization of smart contracting by making DAO governance, instrument construction, protocol upgrades, agreements, rights management and scope enforcement a completely on-chain P2P process from contract proposal to due diligance, approval and enactment of smart contract terms and conditions.
					<br />
					<br />
					In doing so, Pairwyse enables DAOs to tokenize their creditworthiness, issue risk products with counterparty recourse and engage in DAO-to-DAO transactions from protocol-to-protocol lending to DAO mergers and divestitures -- all competely on-chain.{" "}
				</Text>
				<Link
					href="/login"
					sm-padding="15px 20px 15px 20px"
					hover-background="rgba(5, 165, 255, 0)"
					border-width="1px"
					border-style="solid"
					font="--lead"
					sm-margin="0px 22px 0px 0px"
					border-color="rgba(255, 255, 255, 0.3)"
					md-width="100%"
					md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					text-decoration-line="initial"
					color="--white"
					margin="0px 44px 0px 0px"
					hover-color="--primary"
					hover-border-color="--color-primary"
					md-text-align="center"
					background="--color-primary"
					padding="15px 30px 15px 30px"
					border-radius="10px"
					sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
					href="https://github.com/akiva-capital-holdings/pairwyse-dsl/blob/workshops/demo/Pairwyse_workshops.md"
					target="_blank"
				>
					Creator Workshops
				</Link>
			</Box>
		</Section>
		<Section padding="36px 0 80px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0" background="#000000">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="50%"
				padding="0px 40px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Pairwyse is a horizontal smart contracting protocol
				</Text>
				<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>
					Pairwyse enables pure on-chain manufacture of infrastructure, protocols, instruments, facilities, agreements and interoperable contracts by extending Turing-completeness to the smart contract itself under strictly immutable constitutional governance, security and contract scope enforcement.
				</Text>
				<Box
					lg-margin="32px 0px 0px 0px"
					md-width="100%"
					md-margin="36px 0px 0px 0px"
					display="flex"
					margin="72px 0px 0px 0px"
				>
					<Link
						sm-margin="0px 22px 0px 0px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="/login"
						text-decoration-line="initial"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						sm-padding="15px 13px 15px 13px"
						md-text-align="center"
						lg-margin="0px 32px 0px 0px"
						padding="15px 30px 15px 30px"
						border-radius="10px"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-padding="15px 16px 15px 16px"
						hover-color="--primary"
						color="--white"
						margin="0px 44px 0px 0px"
						lg-padding="15px 23px 15px 23px"
						md-width="50%"
						background="--color-primary"
						font="--lead"
						hover-background="--color-white"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						href="https://github.com/akiva-capital-holdings/pairwyse-dsl"
						target="_blank"
					>
						Github
					</Link>
				</Box>
			</Box>
			<Box
				empty-border-style="solid"
				width="70%"
				display="flex"
				justify-content="flex-end"
				empty-border-width="1px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				md-width="100%"
				md-order="-1"
				empty-min-width="64px"
			>
				<Image
					width="100%"
					md-margin="0px 0px 30px 0px"
					src="https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/Screen+Shot+2023-08-12+at+2.36.13+PM.png"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 24px 0" sm-padding="40px 0 24px 0" md-padding="40px 0 24px 0" background="#f2f2f2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					src="https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/UCS_3.png"
					left="-140px"
					max-width="100%"
					object-fit="cover"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="50%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>
				<Text
					width="85%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline4"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Scaling Governance and Smart Contracts without limits
				</Text>
				<Text
					font="--base"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					In the current world of DAOs and smart contract instruments, the design patterns for upgradability are quite limited. If a significant upgrade is required for the governance contracts of a DAO, the process typically involves taking down the old contracts, resulting in a period where the DAO lacks functionality, followed by a subsequent upgrade that enables new features.
					<br />
					<br />
					However, with Pairwyse, a design pattern was developed for scope-restricted continuous smart contract upgrades, akin to the concept of Continuous Integration with Continuous Deployment (CI/CD) under Quality Assurance constraints. This paradigm enables on-chain upgrades to be made in a continuous manner without the risk of rights erasure or severability of terms.{"  "}
					<br />
					<br />
					As a result, smart contracts become fully decentralized with on-chain mechanisms for recourse, term negotiaion and bylaw legislation. Pairwyse also enables DAOs (and their counterparties) to construct and evolve smart contract systems, protocols and instruments in truly agile and cost-effective manner no matter the complexity.
					<br />
					<br />
				</Text>
				<Link
					sm-padding="15px 20px 15px 20px"
					hover-background="rgba(5, 165, 255, 0)"
					border-width="1px"
					border-style="solid"
					font="--lead"
					sm-margin="0px 22px 0px 0px"
					border-color="rgba(255, 255, 255, 0.3)"
					md-width="100%"
					md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					text-decoration-line="initial"
					color="--white"
					margin="0px 44px 0px 0px"
					hover-color="--primary"
					hover-border-color="--color-primary"
					md-text-align="center"
					background="--color-primary"
					padding="15px 30px 15px 30px"
					border-radius="10px"
					sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
					href="https://zerocap.com/research-lab/limits-of-smart-contracts-dao-pairwyse-protocol/"
					target="_blank"
				>
					Read the Whitepaper
				</Link>
			</Box>
		</Section>
		<Section padding="36px 0 80px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0" background="#000000">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="50%"
				padding="0px 40px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Building a decentralized marketplace ecosystem around Pairwyse
				</Text>
				<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>
					In addition to enabling deployment of fully decentralised protocols and DAOs, an effort is underway to nucleate a DAO of smart contract product creators along with decentralised marketplaces for on-chain solutions, instruments and opportunity discovery.
				</Text>
				<Box
					lg-margin="32px 0px 0px 0px"
					md-width="100%"
					md-margin="36px 0px 0px 0px"
					display="flex"
					margin="72px 0px 0px 0px"
				>
					<Link
						sm-margin="0px 22px 0px 0px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="/login"
						text-decoration-line="initial"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						sm-padding="15px 13px 15px 13px"
						md-text-align="center"
						lg-margin="0px 32px 0px 0px"
						padding="15px 30px 15px 30px"
						border-radius="10px"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-padding="15px 16px 15px 16px"
						hover-color="--primary"
						color="--white"
						margin="0px 44px 0px 0px"
						lg-padding="15px 23px 15px 23px"
						md-width="50%"
						background="--color-primary"
						font="--lead"
						hover-background="--color-white"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						href="https://t.me/pairwyse_community"
						target="_blank"
					>
						Telegram Community
					</Link>
					<Link
						padding="15px 30px 15px 30px"
						font="--lead"
						border-radius="10px"
						border-width="2px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						border-style="solid"
						md-width="50%"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-text-align="center"
						sm-padding="15px 13px 15px 13px"
						text-decoration-line="initial"
						color="--white"
						background="rgba(255, 5, 5, 0)"
						margin="0px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0.3)"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						hover-background="--color-white"
						hover-color="--indigo"
						href="/login"
						lg-padding="15px 23px 15px 23px"
						md-padding="15px 16px 15px 16px"
						href="https://twitter.com/pairwyse"
						target="_blank"
					>
						Follow on Twitter_X
					</Link>
				</Box>
			</Box>
			<Box
				empty-border-style="solid"
				width="70%"
				display="flex"
				justify-content="flex-end"
				empty-border-width="1px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				md-width="100%"
				md-order="-1"
				empty-min-width="64px"
			>
				<Image
					width="100%"
					md-margin="0px 0px 30px 0px"
					src="https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/PW_DAO_ROADMAP.png"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
				/>
			</Box>
		</Section>
		<Section background="linear-gradient(264.47deg,#000000 0%,#000708 29.39%)" padding="36px 0 36px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="20%"
				sm-width="60%"
				display="flex"
				align-items="center"
				empty-min-width="100px"
				empty-min-height="100px"
				empty-border-color="LightGray"
			>
				<Image src="https://pairwyse.org/static/media/logo.svg" max-width="171px" />
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="100%"
				sm-width="50%"
				align-items="center"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override
						slot="Content"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
					/>
					<Override slot="Children" justify-content="center" display="flex" align-items="center" />
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Box
						display="flex"
						align-items="center"
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
					>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							font="--base * 0.75"
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color="--grey"
							margin="0px 15px 0px 15px"
							opacity="0.95"
							href="https://zerocap.com/research-lab/limits-of-smart-contracts-dao-pairwyse-protocol/"
							target="_blank"
						>
							Whitepaper
						</Link>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							font="--base * 0.75"
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color="--grey"
							margin="0px 15px 0px 15px"
							opacity="0.95"
							href="https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/Clinaro%2C+Ltd+-+Pairwyse+Whitepaper+-+Original.pdf"
							target="_blank"
						>
							Technical
						</Link>
						<Link
							color="--grey"
							margin="0px 15px 0px 15px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							font="--base * 0.75"
							text-decoration-line="initial"
							opacity="0.95"
							hover-opacity="1"
							md-color="--indigo"
							href="https://tetsuya-kuroko-pairwyse-dsl.readthedocs-hosted.com/en/latest/"
							target="_blank"
						>
							Docs
						</Link>
						<Link
							color="--grey"
							margin="0px 15px 0px 15px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							font="--base * 0.75"
							text-decoration-line="initial"
							opacity="0.95"
							hover-opacity="1"
							md-color="--indigo"
							href="https://github.com/akiva-capital-holdings/pairwyse-dsl"
							target="_blank"
						>
							Github
						</Link>
						<Link
							color="--grey"
							margin="0px 15px 0px 15px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							font="--base * 0.75"
							text-decoration-line="initial"
							opacity="0.95"
							hover-opacity="1"
							md-color="--indigo"
							href="https://github.com/akiva-capital-holdings/pairwyse-dsl/blob/workshops/demo/Pairwyse_workshops.md"
							target="_blank"
						>
							Workshops
						</Link>
						<Link
							color="--grey"
							margin="0px 15px 0px 15px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							font="--base * 0.75"
							text-decoration-line="initial"
							opacity="0.95"
							hover-opacity="1"
							md-color="--indigo"
							href="https://t.me/pairwyse_community"
							target="_blank"
						>
							Telegram
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base * 0.75"
							color="--grey"
							opacity="0.95"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							text-decoration-line="initial"
							margin="0px 15px 0px 15px"
							href="https://twitter.com/pairwyse"
							target="_blank"
						>
							Twitter_X
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base * 0.75"
							color="--grey"
							opacity="0.95"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							text-decoration-line="initial"
							margin="0px 15px 0px 15px"
							href="https://github.com/akiva-capital-holdings/pairwyse-dsl/blob/master/demo/nivaura/NIVAURA_DEMO.md"
							target="_blank"
						>
							Testnet
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>

		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});