export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"64d7936e477c3a0019b68ac5",
				"64d7936e477c3a0019b68ac9"
			]
		},
		"64d7936e477c3a0019b68ac5": {
			"id": "64d7936e477c3a0019b68ac5",
			"name": "404",
			"pageUrl": "404"
		},
		"64d7936e477c3a0019b68ac9": {
			"id": "64d7936e477c3a0019b68ac9",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_apple_152px": "https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/static/media/favicon.ico",
			"favicon_32px": "https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/static/media/favicon.ico",
			"favicon_win10_270px": "https://s3.ap-southeast-1.amazonaws.com/pairwyse.io/static/media/favicon.ico",
			"background_win10": "#000848",
			"og:title": "Pairwyse Protocol for on-chain smart contract decentralization, development and governance",
			"og:description": "Pairwyse protocol for on-chain P2P and multi-party agreements, DeFi instruments, risk management, scope enforcement, rights management, counterparty recourse and fully decentralized DAO governance on the blockchain",
			"og:image": "favicon.ico",
			"title": "Pairwyse Protocol",
			"description": "Pairwyse protocol for on-chain P2P and multi-party agreements, DeFi instruments, risk management, scope enforcement, rights management, counterparty recourse and fully decentralized DAO governance on the blockchain"
		}
	}
}